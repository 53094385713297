.dashboardPageWrap {
    padding: 37px 0;
    .profileBox {
        height: 100%;
        border-radius: 3px;
        background: #ffffff;
        box-shadow: 0px 2px 18px rgba(19, 18, 18, 0.05);
        padding-top: 23px;
        figure {
            display: flex;
            width: 158px;
            height: 158px;
            border-radius: 50%;
            border: 5px solid var(--bs-color1);
            margin: auto;
            img {
                max-width: 100%;
                border-radius: 50%;
                width: 150px;
                max-height: 100%;
                // margin: auto;
                object-fit: cover;
            }
        }
        h4 {
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            /* or 123% */
            text-transform: capitalize;

            color: #000000;
        }
        p {
            font-weight: 500;
            font-size: 13px;
            line-height: 25px;
            /* identical to box height, or 192% */
            text-transform: capitalize;

            color: #7f7f7f;
            margin: 0;
        }
        hr {
            background-color: rgba(#9e9e9e, 0.2);
            margin: 20px 0;
        }
        .progressLine {
            padding: 0 16px;
            h6 {
                font-weight: normal;
                font-size: 14px;
                line-height: 25px;
                /* identical to box height, or 179% */
                text-transform: capitalize;
                margin-bottom: 14px;
                color: #000000;
            }
            .pg-back {
                background-color: #f0f2f4;
                border-radius: 15px;
                overflow: hidden;
                width: 100%;
                .pg-upgrade {
                    height: 8px;
                    background-color: var(--bs-color1);
                }
            }
        }
        .redirect {
            padding: 14px 16px 35px;
            display: flex;
            align-items: center;
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                text-transform: capitalize;
                color: #000000;
            }
            a {
                display: inline-flex;
                margin-left: 6px;
                color: var(--bs-color1);
                &:hover {
                    margin-left: 12px;
                    color: #000000;
                }
            }
        }
    }

    .countingCardsWrap {
        > .row {
            > div {
                &:nth-child(1) {
                    margin-bottom: 30px;
                }
                &:nth-child(2) {
                    margin-bottom: 30px;
                }
            }
        }
        a {
            height: 100%;
            display: flex;
            align-items: center;
            border-radius: 3px;
            background: #ffffff;
            box-shadow: 0px 2px 18px rgba(19, 18, 18, 0.05);
            margin-bottom: 30px;
            padding: 20px 40px;
            position: relative;
            border-radius: 3px;
            &:hover {
                .arrow {
                    color: #000000;
                }
            }
            .row {
            }
            .arrow {
                color: var(--bs-color1);
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                transition: ease 0.3s;
            }
            h3 {
                font-weight: bold;
                font-size: 30px;
                line-height: 35px;
                text-transform: capitalize;
                color: var(--bs-color1);
            }
            h4 {
                margin: 0;
                font-weight: 500;
                font-size: 26px;
                line-height: 30px;
                text-transform: capitalize;

                color: #181414;
            }
        }
    }
}

@media (min-width: 1100px) and (max-width: 1600px) {
    .dashboardPageWrap {
        padding: 30px 0;
        .profileBox {
            figure {
                width: 120px;
                height: 120px;
            }
            h4 {
                font-size: 1.2rem;
                line-height: normal;
            }
            p {
                font-size: 11px;
                line-height: normal;
                margin: 0;
            }
            hr {
                margin: 12px 0;
            }
            .progressLine {
                h6 {
                    font-size: 12px;
                    line-height: normal;
                    margin-bottom: 0;
                }
            }
        }
        .countingCardsWrap {
            a {
                padding: 8px 18px;
                .row {
                    svg {
                        height: 54px;
                    }
                }
                h3 {
                    font-size: 1.25rem;
                    margin: 0;
                    line-height: 1;
                }
                h4 {
                    font-size: 1rem;
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .dashboardPageWrap {
        .countingCardsWrap {
            a {
                padding: 15px;
                h4 {
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .dashboardPageWrap {
        .countingCardsWrap {
            .row {
                > div {
                    margin-bottom: 30px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 479px) {
    .dashboardPageWrap {
        .countingCardsWrap {
            a {
                padding: 10px 15px;
            }
        }
    }
}

