footer {
  background-color: rgba(#000, 0.05);
  padding: 40px 0;
  //   margin-top: 186px;

  @media (min-width: 1200px) {
    .row {
      > div {
        margin-right: 40px;
        &:first-child {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  @media (min-width: 1440px) {
    .row {
      > div {
        margin-right: 100px;
        &:first-child {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  h5 {
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: capitalize;
    color: #404042;
    margin-bottom: 20px;
  }
  p {
    color: #7a7a7a;
    font-size: 0.9rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul {
    margin: 0;
    color: #7a7a7a;
  }
  .fNav {
    a {
      line-height: 2rem;
      font-size: 0.9rem;
    }
  }
  .logo {
    font-size: 14px;
    a {
      img {
        margin-left: -24px;
      }
    }
    p {
      font-size: inherit;
      margin: 0;
    }
  }
  .tpsNav {
    li {
      padding: 0 3px;
    }
  }
  a {
    color: inherit;
    &:hover {
      color: var(--bs-color1);
    }
  }
}
@media (max-width: 1600px) {
  footer {
    padding: 20px 0 10px;
  }
}
@media (max-width: 991px) {
  footer {
    padding: 60px 0 40px;
    .row {
      > div {
        &:first-child {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 575px) and (min-width: 480px) {
  footer {
    .row {
      > div {
        width: calc(100% / 3);
        &:nth-child(1) {
          width: 50%;
        }
        &:nth-child(2) {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  footer {
    padding: 40px 0 30px;
    .row {
      > div {
        padding-top: 1rem;
      }
    }
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
    h5 {
      line-height: 1;
      margin-bottom: 10px;
    }
    .fNav {
      margin-bottom: 20px;
      li {
        font-size: 14px;
        a {
          line-height: 28px;
        }
      }
    }
  }
}
