.single-row {
    border-top: 1px solid rgba(158, 158, 158, 0.2);
    border-bottom: 1px rgba(158, 158, 158, 0.2);
    padding: 28px 0;
    transition: ease 0.3s;
}
.singlecell {
    // margin-bottom: 12px !important;
    // font-size: 20px !important;
}
.companyName{
    // width:151px;
    transition: .1s ease-in;
    cursor: pointer;
    

    &:hover{
        color: #fbaf41;
        transition: .1s ease-out;
    
    }
}
