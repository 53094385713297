.DashBoardResumesPage {
  .pageTitle {
    padding: 30px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */
    text-transform: capitalize;

    color: #000000;
  }

  .resCard {
    label {
      background: #ffffff;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.11);
      border-radius: 6px;
      height: 150px;

      display: flex;
      .cont {
        margin: auto;
        text-align: center;
        cursor: pointer;
        span {
          display: block;
          padding-top: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          /* identical to box height, or 200% */
          text-align: center;
          text-transform: capitalize;
          color: #202123;
        }

        p {
          padding-top: 8px;
          margin-bottom: 0;
        }
      }
    }
  }

  .afterFileChoose {
    background: #ffffff;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    height: 150px;
    display: flex;
    padding: 0 13px;
    h5 {
      font-weight: 500;
      font-size: 15px;
      line-height: 32px;
      text-align: center;
      text-transform: capitalize;

      color: #202123;
    }
    hr {
      border-top-color: #f0f0f0;
      margin: 18px 0;
    }
    .btn {
      color: #000000;
      transition: ease 0.3s;
      &:hover {
        color: var(--bs-color1);
        transform: scale(1.1);
      }
    }
  }
}

.upload_click {
  border: none;
  border-radius: 5px;
  padding: 7px 20px;
  color: white;
  background: #faaf41 !important;
  transition: 0.2s ease-in;
}

.upload_click:hover {
  color: white;
  background: black !important;
  transition: 0.2s ease-out;
}

.swal-modal {
  background-color: #ffffff;
  border: 3px solid white;
}
.swal-text {
  color: black;
}

.tooltiptext {
  visibility: hidden;
  width: 70px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 6px;
  position: absolute;
  z-index: 1;
  margin-top: 40px;
  background-color: rgba(128, 128, 128, 0.13);
}

.px-3:hover .tooltiptext {
  visibility: visible;
  color: orange;
  font-size: 13px;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #f8af41;
  font-size: 12px;
  color: white;
  border: none;
  // border: 1px solid #3e549a;
  // text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.swal-button:hover {
  color: white;
  background-color: black !important;
}

.resumeNameDisplay {
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 1600px) and (min-width: 1100px) {
  .DashBoardResumesPage {
    .pageTitle {
      text-align: center;
    }
    .resCard label {
      height: 120px;

      .cont {
        svg {
          height: 28px;
        }
        span {
          font-size: 11px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 575px) and (min-width: 480px) {
  .DashBoardResumesPage {
    .row {
      > div {
        width: calc(100% / 2);
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .DashBoardResumesPage .afterFileChoose h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #202123;
  }
}

@media (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .DashBoardResumesPage .afterFileChoose h5 {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #202123;
  }
}

@media (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  .DashBoardResumesPage .afterFileChoose h5 {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #202123;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .DashBoardResumesPage .afterFileChoose h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #202123;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .DashBoardResumesPage .afterFileChoose h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: #202123;
  }
}
.heart {
  color: #f8af41 !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .DashBoardResumesPage .resCard label .cont span {
    line-height: 24px !important;
  }
  .pages {
    min-height: 34vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .pages {
    min-height: 32vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media (min-width: 1281px) {
  .pages {
    margin-top: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
