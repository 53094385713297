.DashboardProfilePage {
    .pageTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        /* identical to box height, or 125% */
        text-transform: capitalize;

        color: #000000;
        padding: 35px 0;
    }
    .leftBlock {
        position: relative;
        padding: 26px 16px;
        background: #ffffff;
        box-shadow: 0px 2px 18px rgba(19, 18, 18, 0.05);
        margin-bottom: 40px;
        .hrBox {
            margin: 0 16px;
        }
        hr {
            background-color: #9e9e9e;
        }
        .description {
            h5 {
                font-weight: 500;
                font-size: 17px;
                line-height: 25px;
                /* identical to box height, or 147% */
                text-transform: capitalize;

                color: #000000;
            }
            p {
                font-weight: normal;
                font-size: 13px;
                line-height: 25px;
                /* or 192% */
                text-transform: lowercase;

                color: #000000;
            }
        }
    }
    .profile {
        .editLink {
            position: absolute;
            top: 15px;
            right: 25px;
            a {
                color: var(--bs-color1);
                &:hover {
                    span {
                        color: #000000;
                    }
                }
                span {
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 25px;
                    /* identical to box height, or 147% */
                    text-transform: capitalize;
                    padding-left: 8px;
                    color: #fbaf41;
                }
            }
        }

        figure {
            width: 126px;
            height: 126px;
            border-radius: 50%;
            border: 5px solid #fbaf41;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        h4 {
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            /* or 123% */
            text-transform: capitalize;

            color: #000000;
        }
        p {
            margin: 0;
            small {
                font-weight: 500;
                font-size: 13px;
                line-height: 25px;
                /* identical to box height, or 192% */
                text-transform: capitalize;

                color: #000000;
                a {
                    color: #7f7f7f;
                    &:hover {
                        color: var(--bs-color1);
                    }
                }
            }
        }
    }

    .rightBlock {
        position: relative;
        padding: 36px 16px 0.5rem;
        background: #ffffff;
        box-shadow: 0px 2px 18px rgba(19, 18, 18, 0.05);
        margin-bottom: 40px;
        .changePass {
            position: absolute;
            top: 35px;
            right: 25px;
            a {
                color: var(--bs-color1);
                &:hover {
                    span {
                        color: #000000;
                    }
                }
                span {
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 25px;
                    /* identical to box height, or 147% */
                    text-transform: capitalize;
                    padding-left: 8px;
                    color: #fbaf41;
                }
            }
        }
        .formBox {
            margin-bottom: 36px;
        }
        .label {
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            /* identical to box height, or 147% */
            text-transform: capitalize;
            margin-bottom: 15px;
            color: #000000;
        }
        .form-control {
            padding: 0 30px;
            border: 1px solid #cecece;
            border-radius: 6px;
            font-size: 18px;
            line-height: 52px;
            box-shadow: none !important;
            height: auto;
        }
        label {
            display: block;
            margin-bottom: 20px;
            input:checked + .box {
                color: #181414;
            }
            .box {
                position: relative;
                border: 1px solid #f2f2f2;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                width: 100%;
                align-items: center;
                color: #fff;
                cursor: pointer;
                .checked {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                }
                h6 {
                    margin: 0;
                    padding: 10px 0;

                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: capitalize;

                    color: #181414;
                }
            }
        }
    }

    .deleteAccountBtn {
        .btn {
            color: var(--bs-color1);
            &:hover {
                color: #000;
            }
        }
    }
}

@media (max-width: 575px) and (min-width: 480px) {
    .DashboardProfilePage .rightBlock {
        .formBox {
            .row {
                > div {
                    width: calc(100% / 3);
                }
            }
        }
    }
}

@media (max-width: 475px) {
    .DashboardProfilePage {
        .rightBlock .changePass {
            right: 15px;
            a {
                span {
                    font-size: 14px;
                }
            }
        }

        .deleteAccountBtn {
            .btn {
                width: 100%;
                background-color: var(--bs-color1);
                &:hover {
                    background-color: #000;
                }
            }
        }
    }
}
