.DashboardModule {
  padding-top: 69px;
  // min-height: 90vh;
  .dashboardMenu {
    background-color: #f8f8f8;
    // border-top: 1px solid rgba(#9e9e9e, 0.2);
    border-bottom: 1px solid rgba(#9e9e9e, 0.2);
    @media (max-width: 991px) {
      padding: 22px 0 8px;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
    nav {
      overflow: hidden;
      ul {
        // margin: 0 -32px;
        &::-webkit-scrollbar {
          height: 2px;
          width: 2px;
        }
        li {
          padding: 0 32px;
          a {
            display: flex;
            height: 58px;
            align-items: center;
            border-bottom: 2px solid transparent;
            color: #8c8c8c;
            padding: 0 5px;
            &.active,
            &:hover {
              color: var(--bs-color1);
              border-color: var(--bs-color1);
              margin-bottom: -1px;
              svg {
                fill: #fbaf41;
              }
              span {
                color: #000;
              }
            }
            svg {
              margin-right: 12px;
              margin-top: 14px;
            }
            span {
              white-space: nowrap;
              display: block;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}

.pages {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > * {
    width: 100%;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .pages {
    min-height: 50vh;
  }
}

@media (max-width: 767px) {
  .DashboardModule {
    padding-top: 0;
  }
}

@media (max-width: 475px) {
  .DashboardModule .dashboardMenu nav ul li {
    padding: 0 12px;
  }
}

.new_justify {
  justify-content: flex-start;
  @media (max-width: 1199px) {
    padding-bottom: 5px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(158, 158, 158, 0.2);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(158, 158, 158, 0.2);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(158, 158, 158, 0.2);
  }
}
.nav-menu {
  width: 20px;
  display: none;
  &:hover nav {
    opacity: 1;
  }
  @media (max-width: 991px) {
    display: block;
  }
}
@media (max-width: 991px) {
  .dashboardMenu {
    .display-block {
      display: inline-block !important;
      width: 100%;
      transition: all 0.5s ease;
    }
  }
  .dashboardMenu {
    nav {
      display: none;
    }
  }
}
.dashboardMenu {
  nav.mobile-menu {
    display: block !important;
  }
}

.new-design-all-dakhboard-menu {
  li {
    padding: 0 23px !important;
  }
}
