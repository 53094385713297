.passwordForm {
    .form-group {
        margin-bottom: 0px;
    }
    .label {
        margin-bottom: 3px !important   ;
    }
}

.customBtn {
    margin: 8px 0px;
}

.changePasswordBeforeLogin{
    min-height: 65vh;
    margin-top: 80px;
}