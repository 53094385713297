.companyInsights {
  border: 1px solid rgb(251, 175, 65);
  padding: 0px;

  .insight-header {
    background-color: #fbaf41;
    justify-content: center;
    padding: 0px 8px;
    color: white;

    .insight-header-text {
      color: white;

      &.companyinsights {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 36px;

        @media(max-width:576px) {
          line-height: 30px;
        }
      }
    }
  }

  .insight-content {
    justify-content: center;

    .insight-icons {
      width: 29px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      box-shadow: inset 0 0 0 2px #ffffff;
    }
  }

  .insight-icon-list {
    // border-left: 1px solid grey;
    padding: 5px 20px;
  }


}

.TooltipBox {
  overflow: none;
  position: relative;
  z-index: 1000;
  border: 1px solid orange;
}

.financial_tooltip {
  overflow: none;
  position: relative;
  z-index: 1000;
  border: 1px solid orange;
}

.satisfaction_tooltip {
  overflow: none;
  position: relative;
  z-index: 1000;
  border: 1px solid orange;
}

.partThree {
  h5 {
    margin-bottom: 0;
  }
}

.job-match {
  display: flex;
  justify-content: space-between;

  @media (max-width:467px) {
    display: block;
  }

  h5 {
    @media (max-width:467px) {
      font-size: 13px !important;
    }
  }
}

.lineBox {
  width: 400px;

  @media (max-width:467px) {
    width: 100%;
  }
}

.progressBox {
  margin-top: -11px;

  @media (max-width:777px) {
    overflow: hidden;
  }

}


.after-click-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width:467px) {
    flex-wrap: wrap;
    ;
  }
}

.apply-btn {
  width: 140px;
  height: 36px;
  line-height: 30px;
  padding: 0;

}

.after-click-next {
  width: 50%;

  @media(max-width:467px) {
    width: 100%;
  }
}

.Apply-now {

  font-weight: 500;

  @media(max-width:467px) {
    padding-left: 0;
  }
}


//  .Apply-now:hover{
//   color: #fbaf41;

//  }
.job-title-top {
  h5 {
    font-size: 14px;
  }
}

.apply-md {
  padding: 0;

  @media(max-width:467px) {
    padding: 0;
  }
}

// .apply-btn{
//   background: #efa522;
//   color: #fff;
//   width: 140px;
//   height: 36px;
//   border-radius: 10px;
//   text-align: center;
//   padding-top: 3px;
//   &:hover{
//     background-color: #000;
//   }
// }
.apply-icon {
  width: 27px;

  // filter: invert(85%) sepia(16%) saturate(5691%) hue-rotate(338deg) brightness(94%) contrast(100%);
  @media(max-width:500px) {
    filter: invert(0);
    width: 23px;
  }
}



.apply-button {
  background: transparent;
  position: relative;
  border: none;
  outline: none;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 41px;
  overflow: hidden;
  transition: 0.5s width ease-in-out;

  @media(max-width:500px) {
    width: 129px;
    height: 35px;
    border: 1px solid #efa522;
    background: #fff;
    border-radius: 50px;
    margin-left: 3px;

  }

  .apply-text {
    position: absolute;
    top: 50%;
    transition: 0.5s ease-in-out;
    transform: translateY(-50%);
    right: -1000%;

    @media(max-width:500px) {
      right: 17%;
      // left: 17px;
      color: black;
      font-size: 14px;
      // width: 129;
    }

  }

  &:hover {
    // background: #efa522;
    background: #ffffff;
    border-radius: 30px;
    // border-color: #efa522;
    border: 1px solid #efa522;
    width: 150px !important;
    color: #fff;

    @media(max-width:500px) {
      background: #fff;
      border: 1px solid #efa522;
      width: 129px !important;

    }

    .apply-icon {
      // filter: invert(1);

    }

    .apply-text {
      right: 17%;
      color: rgb(0, 0, 0);

      @media(max-width:500px) {
        right: 17%;
        color: black;
        font-size: 14px;
      }
    }
  }
}


.heart-button {
  background: transparent;
  position: relative;
  border: none;
  outline: none;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 41px;
  overflow: hidden;

  transition: 0.5s width ease-in-out;

  @media(max-width:500px) {
    background: #ffffff;
    border: 1px solid #ef9c0a;
    border-radius: 50px;
    width: 143px !important;
    height: 35px;
  }

  &:hover {
    // background: #efa522;
    background: #ffffff;
    border-radius: 30px;
    // border-color: #efa522;
    border: 1px solid #ef9c0a;
    width: 161px !important;
    // color: #fff;
    color: #181414;

    @media(max-width:500px) {
      background: #ffffff;
      border: 1px solid #ef9c0a;
      width: 143px !important;
      // height: 35px;
    }

    .fa-heart {
      padding-left: 9px;

      @media(max-width:500px) {
        font-size: 18px !important;
        margin-top: 0px;
        padding-left: 9px !important;
        color: black;
      }
    }

    .favourite-icon {
      color: rgb(10, 10, 10);
      right: 20px;

      @media(max-width:500px) {
        font-size: 14px !important;
        color: black;
        right: 0;

      }
    }
  }

  .favourite-icon {
    position: absolute;
    top: 50%;
    transition: 0.5s ease-in-out;
    transform: translateY(-50%);
    right: -1000%;

    @media(max-width:500px) {
      right: 0;
      left: 22px;
      color: black;
      font-size: 14px;
    }

  }

  .fa-heart {
    margin: 0;
    font-size: 23px;

    @media(max-width:500px) {
      padding-left: 8px;
      color: #fff;
      font-size: 18px !important;
      padding-left: 9px !important;
      color: #181414;
    }
  }
}

.fav-icon {
  @media(max-width:500px) {
    flex-wrap: nowrap;

  }
}