.authLogo {
    padding: 30px 0;
    svg {
        height: 80px;
    }
}

@media (max-width: 767px) {
    .authLogo {
        padding: 40px 0 28px;
        a {
            display: inline-flex;
        }
        svg {
            height: 70px;
        }
    }
}
