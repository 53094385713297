.resultSummary{
    display: flex;
    background-color: #efa521;
    // width: 40%;
    padding: 20px 3px 20px 0px;
    // margin-left: 10px;
    margin:0 0 20px  0;
    color: white ;
    padding-left: 17px;
    a{
        &:hover{
            color:white;
            cursor: default;
        }
    }
    
}
.result-no{
    padding-left: 18px;

    width: 93%;
    word-break: break-all;
    
}
.active-new{
    color:#fff;
}