.blankDiv{
  margin: 113px 0 0 0 ;
  @media (max-width:767px) {
    margin: 30px 0 0 0 ;
  }
}

.homePageOne {
  padding: 37px 0 82px;
  p {
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 70px;
    b {
      font-weight: 500;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-transform: capitalize;
    color: #404042;
    margin-bottom: 20px;
  }
  ul {
    li {
      padding: 10px;
      a {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 25px;
        background-color: #e5e5e5;
        color: #000;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          background-color: var(--bs-color1);
        }
        span {
          padding-left: 4px;
        }
      }
    }
  }
}

.recommendedJobSection {
  padding-bottom: 120px;
  border-bottom: 1px solid rgba(#000000, 0.2);
  h3 {
    text-align: center;
    color: var(--bs-color1);
    font-size: 2rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 1.5rem;
    span {
      color: var(--bs-color2);
    }
  }
  .row {
    > div {
      margin-bottom: 40px;
    }
  }
  .card {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 1.5rem;
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    h4 {
      font-weight: 500;
      font-size: 24px;
      color: #000;
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 18px;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 20px;
    }
    .h5 {
      color: #404042;
      font-size: 18px;
      text-transform: capitalize;
      padding-left: 5px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 17px;
      font-weight: 500;
      text-transform: capitalize;
      color: #000;
    }
    p {
      padding-top: 20px;
      font-size: 14px;
      color: #000;
    }
    a {
      padding: 0 35px;
      line-height: 50px;
      height: 50px;
      border-radius: 6px;
      background-color: var(--bs-color1);
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      &:hover {
        background-color: #000;
      }
    }
  }
  .allBtn {
    text-align: center;
    padding-top: 20px;
    .btn {
      padding: 0 35px;
      line-height: 54px;
      height: 54px;
      border-radius: 6px;
      background-color: var(--bs-color1);
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      &:hover {
        background-color: #000;
      }
    }
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .homePageOne {
    padding: 37px 0 40px;
    h3 {
      font-size: 1.8rem;
      line-height: normal;
    }
    p {
      font-size: 1rem;
      line-height: normal;
      margin-bottom: 3rem;
    }

    ul {
      li {
        a {
          font-size: 0.9rem;
          height: 42px;
          padding: 0 12px;
        }
      }
    }
  }
  .recommendedJobSection {
    padding-bottom: 5rem;
    .row {
      > div {
        margin-bottom: 1.5rem;
      }
    }
    .card {
      h4 {
        font-size: 1.25rem;
      }
      svg {
        height: 1rem;
        width: auto;
      }
      h5 {
        font-size: 1rem;
        margin-bottom: 0.8rem;
      }
      .h5 {
        font-size: 1rem;
        margin-bottom: 0.8rem;
      }
      .h6 {
        font-size: 0.8rem;
      }
      p {
        padding-top: 0.5rem;
        font-size: 0.8rem;
      }
      .link {
        a {
          height: 36px;
          line-height: 34px;
          font-size: 0.8rem;
          padding: 0 1.5rem;
        }
      }
    }
    .allBtn {
      .btn {
        height: 36px;
        line-height: 34px;
        font-size: 0.8rem;
        padding: 0 1.5rem;
      }
    }
  }
}
@media (max-width: 991px) {
  .homePageOne {
    ul {
      li {
        a {
          font-size: 14px;
          height: 48px;
          padding: 0 20px;
        }
      }
    }
  }

  .recommendedJobSection {
    padding-bottom: 70px;
    h3 {
      font-size: 28px;
    }
    .card {
      h4 {
        font-size: 20px;
      }
      h5 {
        font-size: 14px;
      }
      .h5 {
        font-size: 16px;
      }
      .h6 {
        font-size: 14px;
      }
      a {
        padding: 0 25px;
        line-height: 40px;
        height: 40px;
        font-size: 14px;
      }
    }
    .allBtn {
      .btn {
        padding: 0 25px;
        line-height: 40px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 575px) {
  .homePageOne {
    h3 {
      font-size: 30px;
      line-height: 32px;
    }
    ul {
      li {
        a {
          font-size: 12px;
          height: 42px;
          padding: 0 10px;
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .recommendedJobSection {
    .allBtn {
      padding-top: 0;
    }
    .card {
      padding: 20px;
    }
    h3 {
      span {
        display: block;
      }
    }
  }
}
