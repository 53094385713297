.loginPage {
  .yourRole {
    // margin-bottom: 22px;
    p {
      // margin-bottom: 20px;
    }
    label {
      margin-bottom: 30px;
      > div {
        width: 170px;
        height: 100px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #cecece;
        cursor: pointer;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        color: #000000;
      }
      input:checked + div {
        background: #ffffff;
        border: 1px solid #fbaf41;
        box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.09);
      }
    }
  }
}

@media (max-width: 575px) {
  .loginPage .yourRole label > div {
    width: 100%;
  }
}

.socialsignupmodal {
  .modal-body {
    padding: 40px 30px;
    min-width: 500px;
    @media (max-width: 767px) {
      padding: 30px 15px;
      min-width: 100%;
    }
  }
}
.btnouter button {
  padding: 0 !important;
  border: 0 !important;
}
.btnouter button button {
  padding: 14px 30px !important;
  border-radius: 6px !important;
  border: 1px solid #cecece !important;
  cursor: pointer;
  background: #fff !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: capitalize !important;
  color: #000000 !important;
  > div {
    display: none !important;
  }
  span {
    padding: 0 !important;
    background: transparent !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .dropdown-menu {
    inset: 10px auto auto -86px !important;
    border: none !important;
  }
  .googleLogin button {
    margin-bottom: 30px;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 17px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
    display: flex !important;
    margin: 0 auto 30px;
  }

  .faceBookLogin button {
    height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
    display: flex !important;
    margin: 0 auto;
    align-items: center;
  }
}

@media (min-width: 1281px) {
  .googleLogin button {
    width: 674px;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 15px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
  }

  .faceBookLogin button {
    // width: 674px !important;
    // height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
    svg{
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .googleLogin button {
    width: 90% !important;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 17px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
    margin: 0 auto;
    display: flex !important;
  }

  .faceBookLogin button {
    width: 90% !important;
    height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
    margin: 0 auto;
    display: flex !important;
    align-items: center;
  }
}

@media (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  .googleLogin button {
    width: 500px !important;
    margin-bottom: 30px;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 17px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
  }

  .faceBookLogin button {
    width: 500px !important;
    height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .googleLogin button {
    width: 100%;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 17px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
  }

  .faceBookLogin button {
    width: 100%;
    height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
  }
}

@media (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .googleLogin button {
    width: 100% !important;
    height: 74px;
    border-radius: 40px !important;
    justify-content: center;
    background-color: transparent;
    font-size: 17px !important;
    border: 1px solid #fbaf41 !important;
    box-shadow: none;
    color: #fbaf41 !important;
  }

  .faceBookLogin button {
    width: 100% !important;
    height: 74px;
    background-color: white !important;
    border-radius: 40px !important;
    justify-content: center;
    font-weight: 500px !important;
    font-size: 15px !important;
    border: 1px solid #0a58ca !important;
    box-shadow: none;
    color: #0a58ca !important;
  }
}

.error {
  color: red;
  text-align: left;
  font-weight: normal;
  margin-top: 5px;
  min-height: 26px;
  &:empty {
    min-height: 0px;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .loginPage {
    .yourRole {
      label {
        > div {
          height: 60px;
        }
        span {
          font-size: 1rem;
        }
      }
    }
  }
}
