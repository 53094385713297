.loginPage {
  .card {
    width: 700px;
    max-width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.07);
    border: 0;
    margin: 0 auto 80px;
    padding: 1.25rem 2.5rem;
  }
  h3 {
    font-weight: 500;
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-bottom: 1.25rem;
    color: var(--bs-color2);
  }
  .form-group {
    margin-bottom: 1.25rem;
    .label {
      margin-bottom: 14px;
      color: var(--bs-color3);
      text-transform: capitalize;
    }
    .form-control {
      border: 1px solid #cecece;
      height: 48px;
      line-height: 23px;
      padding: 0 15px;
      border-radius: 6px;
      box-shadow: none !important;
      &:focus {
        border-color: var(--bs-color1);
      }
    }
    input[type="password"] {
      font-size: 1.2rem;
      letter-spacing: 4px;
    }
    span {
      position: absolute;
      top: 50%;
      right: 18px;
      color: rgba(#000, 0.5);
      transform: translateY(-50%);
      cursor: pointer;
      &:hover {
        color: var(--bs-color1);
      }
    }
  }
  .logForg {
    label {
      cursor: pointer;
      svg {
        color: #ddd;
      }
      input:checked + svg {
        color: var(--bs-color1);
      }
      span {
        padding-left: 8px;
      }
    }

    .forgot {
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        &:hover {
          color: var(--bs-color1);
        }
      }
    }
  }

  .loginBtn {
    .btn {
      color: var(--bs-color1);
      padding: 0;
      &:hover {
        color: #000;
      }
    }
  }

  .orLine {
    padding: 1.5rem 0;
    svg {
      width: 100%;
    }
  }

  .googleLogin {
    margin-bottom: 1.25rem;
    a {
      color: var(--bs-color1);
      border: 1px solid var(--bs-color1);
      display: block;
      border-radius: 27px;
      svg {
        width: 100%;
        height: 54px;
      }

      &:hover {
        color: #000;
        border: 1px solid #000;
      }
    }
  }
  .faceBookLogin {
    margin-bottom: 1.25rem;
    a {
      color: #4267b2;
      width: 100%;
      border: 1px solid #4267b2;
      display: block;
      border-radius: 27px;
      svg {
        max-width: 100%;
        height: 54px;
      }
      &:hover {
        color: #000;
      }
    }
  }

  .registerLink {
    text-align: center;
    text-transform: capitalize;
    color: var(--bs-color2);
    a {
      color: var(--bs-color1);
      font-weight: 500;
      &:hover {
        color: #000;
      }
    }
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  :root {
    font-size: 14px;
  }
  .authLogo {
    padding: 20px 0;
    a {
      display: inline-block;
      svg {
        height: 50px;
      }
    }
  }
  .loginPage {
    .card {
      width: 540px;
      margin: auto;
    }
    h3 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .form-group {
      margin-bottom: 1rem;
      .label {
        font-size: 0.8rem;
      }
      .form-control {
        height: 38px;
        line-height: 19px;
      }
    }
    .logForg {
      label {
      }
      .forgot {
        a {
          font-size: 0.9rem;
        }
      }
    }
    .loginBtn {
      padding: 0;
      svg {
        height: 38px;
        width: auto;
      }
    }
    .orLine {
      padding: 1rem 0;
    }
    .googleLogin {
      a {
        svg {
          height: 40px;
        }
      }
    }
    .faceBookLogin {
      a {
        svg {
          height: 40px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .loginPage {
    h3 {
      font-size: 24px;
      text-align: center;
    }
    .card {
      padding: 15px 20px;
      margin: 0 auto 70px;
    }
    .loginBtn {
      .btn {
        display: block;
        width: 100%;
        background-color: var(--bs-color1);
        &:hover {
          background-color: #000;
        }
        svg {
          width: 100%;
          rect {
            width: 100%;
          }
        }
      }
    }
    .orLine {
      padding: 35px 0 15px;
    }
    .googleLogin {
      margin-bottom: 0;
    }
    .faceBookLogin {
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 479px) {
  .loginPage {
    .forgot {
      text-align: center;
      width: 100%;
      padding-top: 1.2rem;
    }
  }
}
.googleLogin button {
  width: 100% !important;
  height: 52px !important;
  border-radius: 40px !important;
  justify-content: center;
  background-color: transparent;
  font-size: 17px !important;
  border: 1px solid #fbaf41 !important;
  box-shadow: none;
  color: #fbaf41 !important;
}
.googleLogin button:hover {
  color: black !important;
  border: 1px solid black !important;
}

.faceBookLogin button {
  width: 100% !important;
  height: 52px !important;
  background-color: white !important;
  border-radius: 40px !important;
  justify-content: center;
  font-weight: 500px !important;

  font-size: 15px !important;
  border: 1px solid #0a58ca !important;
  box-shadow: none;
  color: #0a58ca !important;
}

.faceBookLogin #facebook_btn:hover {
  color: black !important;
  border: 1px solid black !important;
}

#facebook_btn svg {
  font-size: 25px !important;
  margin-right: 10px !important;
}
.shop_modal .yourRole label > div {
  width: 170px;
  height: 100px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  cursor: pointer;
}
.shop_modal .yourRole label input:checked + div {
  background: #ffffff;
  border: 1px solid #fbaf41;
  box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.09);
}
.modal-content {
  // margin-top: 350px;
}
