$theme-colors: (
  "color1": #fbaf41,
  "color2": #404042,
  "color3": #808080,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);
// font-family: 'Roboto', sans-serif;
$font-family-sans-serif: "Roboto", sans-serif;
// @import "@fancyapps/ui/dist/fancybox.css";
@import "~bootstrap/scss/bootstrap.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.myprofileDropNav {
  a {
    display: block;
    padding: 10px 10px;
    &.active {
      color: var(--bs-color1);
    }
  }
}

.mat-select-black {
  .MuiSelect-select:focus {
    background-color: transparent !important;
    // font-family: Helvetica, Arial, sans-serif;
  }
  .MuiSelect-icon {
    color: #000000 !important;
  }
}
.mat-select-dark2 {
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: #94a2b3 !important;
  }
}

section {
  animation: pageAni 0.3s;
}

.hoverBtn {
  &:hover {
    background-color: black;
    border-color: black;
  }
}

@keyframes pageAni {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (min-width: 1440px) {
  .container-xl {
    max-width: 1350px;
  }
}
svg {
  user-select: none;
}
img {
  user-select: none;
}
* {
  outline: none !important;
}
a {
  transition: ease 0.3s;
  text-decoration: none !important;
  cursor: pointer;
}
button {
  transition: ease 0.3s;
  -webkit-appearance: none;
}

@for $fw from 0 to 10 {
  .fw-#{$fw} {
    font-weight: $fw * 100 !important;
  }
}
@for $fade from 0 to 10 {
  .fade-#{$fade} {
    opacity: $fade / 10 !important;
  }
}

// ************ Font size  *************
$fontSizeLastValue: 50;
@for $fontSize from 0 through $fontSizeLastValue {
  .fs-#{$fontSize} {
    font-size: #{$fontSize / 16}rem !important;
  }
}
@each $deviceName, $screenWidth in $grid-breakpoints {
  @media (min-width: $screenWidth) {
    @for $fontSize from 0 through $fontSizeLastValue {
      .fs-#{$deviceName}-#{$fontSize} {
        font-size: #{$fontSize / 16}rem !important;
      }
    }
  }
}
.loginBtn {
  color: #fbaf41 !important;
}
.loginBtn:hover {
  color: #000000 !important;
}
.favroutie-button {
  border: 1px solid #e1e1e1;
  display: inline-flex;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  i {
    margin: auto;
  }
}
.favroutie-button1 {
  display: inline-flex;
  height: 35px;
  width: 30px !important;
  border-radius: 50%;
  
  i {
    margin: auto;
  }
}

.errGap {
  min-height: 24px;
}

.onCampusEventsList {
  .dateCategory {
    margin-top: 30px;
    padding: 0 1rem;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    .dataHead {
      font-family: Roboto;
      font-style: italic;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      text-transform: capitalize;
      margin-bottom: 22px;
      color: #000000;
    }
    .row {
      padding: 5px 0;
      margin-right: calc(-0.5 * var(--bs-gutter-x));
      margin-left: calc(-0.5 * var(--bs-gutter-x));
    }
  }

  .listCategory {
    border-top: 1px solid rgba(#9e9e9e, 0.2);
    padding: 28px 0;
    transition: ease 0.3s;
    &:hover {
      background-color: rgba(#9e9e9e, 0.02);
    }
    &:last-child {
      border-bottom: 1px solid rgba(#9e9e9e, 0.2);
    }
    // .row {
    // }
    .h4 {
      font-size: 22px;
      text-transform: capitalize;
      margin: 0;
      svg {
        margin-right: 10px;
      }
      a {
        color: var(--bs-color1);
        &:hover {
          color: #000000;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .onCampusEventsList {
    .dateCategory {
      margin-bottom: 2rem;
      .dataHead {
        font-size: 1.2rem;
        line-height: normal;
        margin-bottom: 0.8rem;
      }
    }
    .listCategory {
      padding: 0.5rem 0;

      .h4 {
        font-size: 0.9rem;
        padding: 0.5rem 0;
      }
    }
  }
}

// @media (max-width: 1199px) {
//   .onCampusEventsList {
//     .dateCategory {
//       margin-bottom: 45px;
//       .dataHead {
//         font-size: 22px;
//         line-height: normal;
//         margin-bottom: 18px;
//       }
//     }
//     .listCategory {
//       padding: 18px 0;
//       .h4 {
//         font-size: 16px;
//       }
//       .row {
//         > div {
//           &:nth-child(3) {
//             width: auto;
//           }
//           &:last-child {
//             padding-left: 0;
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 767px) {
  .onCampusEventsList {
    .dateCategory {
      margin: 0 -10px;
      margin-bottom: 45px;
      .dataHead {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 18px;
        padding: 0 10px;
      }
    }
    .listCategory {
      padding: 18px 0;
      width: calc(50% - 20px);
      display: inline-flex;
      border: 1px solid rgba(#9e9e9e, 0.2);
      padding: 1rem;
      border-radius: 15px;
      margin: 10px;

      .h4 {
        font-size: 16px;
      }
      .row {
        > div {
          padding: 5px calc(var(--bs-gutter-x) * 0.5);
          &:nth-child(3) {
            width: 100%;
          }
          &:last-child {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
          }
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .onCampusEventsList {
    .dateCategory {
      margin: 0 0;
      margin-bottom: 45px;
      .dataHead {
        padding: 0;
      }
    }
    .listCategory {
      padding: 18px 0;
      width: 100%;
      display: block;
      border: 1px solid rgba(#9e9e9e, 0.2);
      padding: 1rem;
      border-radius: 15px;
      margin: 0 0 10px;

      .h4 {
        font-size: 16px;
      }
      .row {
        > div {
          padding: 5px calc(var(--bs-gutter-x) * 0.5);
          &:nth-child(3) {
            width: 100%;
          }
          &:last-child {
            padding-left: calc(var(--bs-gutter-x) * 0.5);
          }
        }
      }
    }
  }
}

.postcv {
  color: #fbaf41 !important;
}

.postcv:hover {
  color: #000000 !important;
}

.search_job {
  color: #000000;
}
.search_job:hover {
  color: #fbaf41;
}

@media (min-width: 1100px) and (max-width: 1600px) {
}

.changePassWordWrap {
  padding: 9rem 0 5rem;
  min-height: 70vh;

  .btn {
    padding: 7px 20px;
    font-size: 13px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .justify-content-center {
    justify-content: flex-start !important;
  }
}

@media (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  .justify-content-center {
    justify-content: flex-start !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .justify-content-center {
    justify-content: flex-start !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .justify-content-center {
    justify-content: center !important;
  }
}

@media (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .justify-content-center {
    justify-content: flex-start !important;
  }
}
.resultWrap {
  .pagination {
    ul li a {
      width: 32px;
      height: 32px;
      font-size: 12px;
    }
  }
}

.secFooter {
  .pagination {
    // padding-top: 34px;
    ul {
      margin: 0;
      li {
        padding: 0 6px;
        a {
          width: 43px;
          height: 43px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #efeeee;
          color: #b0b0b0;
          &.active {
            background-color: #f0e513 !important;
            color: #323d4f;
          }
        }
      }
    }
  }
}

.active {
  a {
    background-color: #fbaf41 !important;
    color: #323d4f !important;
  }
}
.paginate_li:hover {
  border-color: #b0b0b0 !important;
}

.SearchJobWidget .row .inputBox input {
  padding: 0 15px;
  height: 54px;
  line-height: 27px;
  background-color: white !important;
}
.Mui-selected {
  background-color: #fbaf41 !important;
  width: 44px !important;
  height: 44px !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  width: 44px !important;
  height: 44px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border-radius: 5px !important;
}
.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  width: 2em !important;
  height: 2em !important;
}
.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: transparent !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.css-1s2u09g-control {
  border-color: transparent !important;
}
.css-1s2u09g-control:focus {
  border-color: transparent !important;
}

.MuiBackdrop-root{
  background: rgba(0, 0, 0, 0.7);
}

.loaderCard{
  font-size: 20px;
  background-color: white;
  padding: 32px 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px 0px #484848;
  width: 400px;
  @media(max-width:479px){
    width: 100%;
    max-width: 98%;
    padding: 20px 10px;
  }
  .MuiCircularProgress-root{
    color: rgb(251, 175, 65);
  }
  span{
    margin: 0px 18px;
    color: rgb(251, 175, 65);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}
.filterWrap .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fbaf41;
  outline: 0;
  box-shadow:none;
}
.pageWrapper{
  min-height: 90vh;//100vh
}