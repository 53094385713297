.EmployerJobPage {
    padding: 60px 0;
    .pageTitle {
        font-weight: 500;
        font-size: 30px;
        line-height: 25px;
        text-transform: capitalize;
        color: #000000;
        margin-bottom: 65px;
    }
    .chooseLine {
        margin-bottom: 48px;
        .row {
            width: 818px;
            max-width: 100%;
            margin: 0 auto;
            text-align: center;
            position: relative;
            &::before {
                border-top: 1px solid #b8adad;
                left: 50px;
                right: 50px;
                top: 22px;
                position: absolute;
                content: "";
            }
        }
        .tabLink {
            cursor: pointer;
            position: relative;
            z-index: 2;
            &.active,
            &:hover {
                .figure {
                    color: #fff;
                    background-color: var(--bs-color1);
                    border-color: var(--bs-color1);
                }
                b {
                    color: #000000;
                }
            }
        }
        .figure {
            border: 1px solid #969696;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            display: inline-flex;
            cursor: pointer;
            color: #969696;
            transition: ease 0.3s;
            margin-bottom: 18px;
            background-color: #fff;
            svg {
                margin: auto;
            }
        }
        b {
            display: block;
            color: #969696;
            transition: ease 0.3s;
        }
    }

    .card {
        background: #ffffff;
        box-shadow: 0px 2px 18px rgba(19, 18, 18, 0.05);
        border: 0;
        padding: 36px;
        hr {
            background-color: #e1e0e0;
            opacity: 1;
        }
        .card-body {
            padding: 10px calc(115px - 36px);
        }
        .form-group {
            margin-bottom: 30px;
        }
        .label {
            font-weight: normal;
            font-size: 19px;
            line-height: 22px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 12px;
        }
        .form-control {
            border: 1px solid #cecece;
            border-radius: 6px;
            -webkit-appearance: none;
            box-shadow: none;
        }
        input.form-control {
            height: 62px;
            padding: 0 18px;
            font-size: 19px;
            line-height: 31px;
        }
        select.form-control {
            height: 62px;
            padding: 0 18px;
            font-size: 19px;
            cursor: pointer;
            background-image: url("../downArrow.svg");
            background-position: calc(100% - 23px) center;
            background-repeat: no-repeat;
        }
        textarea.form-control {
            height: 213px;
            resize: none;
        }
        .countingBox {
            padding-top: 15px;
            input {
                margin: 0 13px;
                width: 155px;
                text-align: center;
                font-size: 23px;
                font-weight: 500;
            }
        }
        .timeBox {
            margin-right: 15px;
            padding-top: 15px;
            input {
                background-image: url("../timeIcon.svg");
                background-position: calc(100% - 23px) center;
                background-repeat: no-repeat;
                font-size: 14px;
            }
        }

        .customCheckBox {
            display: inline-flex;
            align-items: center;
            span {
                font-weight: normal;
                font-size: 19px;
                line-height: 22px;
                margin-right: 51px;
                color: #000000;
            }
            input:checked + i {
                background-color: var(--bs-color1);
                box-shadow: inset 0 0 0 2px #fff;
            }
            i {
                border: 2px solid #fbaf41;
                width: 28px;
                height: 28px;
                border-radius: 6px;
                display: block;
                cursor: pointer;
            }
        }

        .card-footer {
            padding: 10px calc(115px - 36px);
            background-color: transparent;
            border: 0;
            .btn {
                padding: 14px 55px;
                font-size: 15px;
                border-radius: 6px;
                font-weight: 500;
                &.bgbtn {
                    background-color: var(--bs-color1);
                    color: #fff;
                    &:hover {
                        background-color: #000000;
                    }
                }
                &.outLine {
                    border: 1px solid #000000;
                    &:hover {
                        background-color: #000000;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .EmployerJobPage {
        .card {
            padding: 25px;
            .card-body {
                padding: 0;
            }
            .card-footer {
                padding: 0;
            }
        }
    }
}

@media (max-width: 575px) {
    .EmployerJobPage {
        .chooseLine {
            .row {
                &::before {
                    top: 18px;
                }
            }
            .figure {
                width: 34px;
                height: 34px;
                svg {
                    width: 12px;
                }
            }
            b {
                font-size: 11px;
            }
        }
        .card {
            padding: 15px;
            .label {
                font-size: 15px;
                line-height: normal;
                margin: 0 0 10px;
            }
            input.form-control {
                height: 54px;
                padding: 0 15px;
                font-size: 14px;
                line-height: 26px;
            }
            select.form-control {
                height: 54px;
                padding: 0 15px;
                font-size: 14px;
            }
            textarea.form-control {
                height: 110px;
            }

            .countingBox {
                svg {
                    height: 54px;
                }
            }
            .countingBox {
                margin-bottom: 1.5rem;
                padding-top: 0;
                input {
                    width: 100%;
                    padding: 0 5px;
                }
            }
            .timeBox {
                width: calc((100% / 2));
                margin: 0;
                padding: 0 15px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                input {
                    width: 100%;
                    background-position: calc(100% - 10px) center;
                }
            }
            .card-footer {
                .btn {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
