.newslettersWrap {
    padding: 60px 0;
    h3 {
        font-size: 30px;
        font-weight: 500;
        color: var(--bs-color2);
        text-align: center;
        margin-bottom: 38px;
        text-transform: capitalize;
        span {
            color: var(--bs-color1);
        }
    }

    .inputBox {
        border: 1px solid #cecece;
        border-radius: 6px;
        padding: 0 20px;
        width: 548px;
        max-width: 100%;
        b {
            font-weight: 500;
            font-size: 18px;
            text-transform: capitalize;
            color: #000000;
        }
        input {
            padding: 0 15px;
            height: 54px;
            line-height: 27px;
        }
    }
    .btn {
        width: 205px;
        height: 54px;
        line-height: 54px;
        padding: 0;
        text-align: center;
        background: var(--bs-color1);
        border-radius: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        &:hover {
            background-color: #000;
        }
    }

    .social {
        padding-top: 38px;
        text-align: center;
        h5 {
            color: var(--bs-color2);
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
        }
        ul {
            margin: 0;
            li {
                a {
                    color: var(--bs-color1);
                    padding: 10px;
                    &:hover {
                        color: #000;
                        filter: grayscale(1) brightness(0);
                    }
                }
            }
        }
    }
}

@media (min-width: 1100px) and (max-width: 1600px) {
    .newslettersWrap {
        padding: 2.5rem 0;
        h3 {
            font-size: 1.5rem;
            margin-bottom: 1.25rem;
        }
        .inputBox {
            width: 450px;
            b {
                font-size: 0.9rem;
            }
            input {
                height: 40px;
                line-height: 19px;
                font-size: 0.9rem;
            }
        }
        .btn {
            width: 140px;
            height: 40px;
            line-height: 38px;
            font-size: 0.9rem;
        }
        .social {
            padding-top: 2rem;
            h5 {
                font-size: 1.2rem;
            }
            img {
                width: 1.5rem;
            }
        }
    }
}

@media (max-width: 991px) {
    .newslettersWrap {
        h3 {
            font-size: 26px;
        }
        .inputBox {
            padding: 0 12px;
            b {
                font-size: 13px;
            }
            input {
                font-size: 13px;
                padding: 0 10px;
                height: 48px;
                line-height: 22px;
            }
        }
        .btn {
            padding: 0 0px;
            line-height: 48px;
            height: 48px;
            font-size: 14px;
            margin: auto;
            display: block;
        }
    }
}

@media (max-width: 479px) {
    .newslettersWrap {
        .btn {
            width: 100%;
        }
        h3 {
            font-size: 20px;
            span {
                display: block;
            }
        }
    }
}
