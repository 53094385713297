.SearchJobWidget {
  position: relative;

  // z-index: 99;
  .TabBoxMain {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
    border-radius: 3px;

    .topNav {
      ul {
        border-bottom: 1px solid rgba(#000000, 0.1);
        padding: 0 20px;
        margin: 0;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        li {
          padding: 0 20px;

          label {
            cursor: pointer;

            span {
              font-size: 18px;
              text-transform: capitalize;
              color: var(--bs-color3);
              display: block;
              line-height: 70px;
              padding: 0 0px;
              border-bottom: 2px solid transparent;
              position: relative;
              z-index: 2;
              margin-bottom: -1px;
              transition: ease 0.3s;
            }

            &:hover {
              span {
                color: #000;
                border-bottom-color: var(--bs-color1);
              }
            }
          }

        }

        li label input:checked+span {
          color: var(--bs-color2);
          border-bottom-color: var(--bs-color1);
          border-bottom: 4px solid var(--bs-color1);
          color: black;
          text-shadow: 5px 17px 51px orange, 12px 22px 42px orange;
        }
      }
    }
  }

  .row {
    margin: 0;
    // padding: 30px 25px;
    padding: 15px 25px 15px 25px;

    .inputBox {
      border: 1px solid #cecece;
      border-radius: 6px;
      padding: 0 20px;

      b {
        font-weight: 500;
        font-size: 14px;
        text-transform: capitalize;
        color: #000000;
      }

      input {
        padding: 0 15px;
        height: 36px;
        line-height: 27px;
      }
    }

    .btn-Custom {
      width: 140px;
      height: 36px;
      line-height: 30px;
      padding: 0;
      text-align: center;
      background: var(--bs-color1);
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;

      &:hover {
        background-color: #000;
      }
    }
  }

  .uploadYourResume {
    padding: 40px 40px;
    animation: pageAni 0.3s;

    label {
      width: 100%;
      margin: 0;
      display: flex;
      border: 1px dashed #bdbdbd;
      background-color: #fcfcfc;
      border-radius: 10px;
      height: 260px;
      margin-bottom: 25px;

      .cont {
        margin: auto;
        text-align: center;
        color: #202123;

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* or 160% */
          text-align: center;
          text-transform: capitalize;
          display: block;
          color: #202123;
          padding-top: 15px;
        }

        a {
          color: #000;
          display: inline-block;

          &:hover {
            color: var(--bs-color1);
            transform: scale(1.2);
          }
        }
      }
    }

    .searchBtn {
      text-align: center;

      .btn {
        width: 257px;
        height: 54px;
        line-height: 54px;
        padding: 0;
        text-align: center;
        background: var(--bs-color1);
        border-radius: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;

        &:hover {
          background-color: #000;
        }
      }
    }
  }

  .ResumeFeedBack {
    padding: 40px 40px;
    animation: pageAni 0.3s;

    label {
      width: 100%;
      margin: 0;
      display: flex;
      border: 1px dashed #bdbdbd;
      background-color: #fcfcfc;
      border-radius: 10px;
      height: 260px;
      margin-bottom: 43px;

      .cont {
        margin: auto;
        text-align: center;

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          /* or 160% */
          text-align: center;
          text-transform: capitalize;
          display: block;
          color: #202123;
          padding-top: 15px;
        }
      }
    }

    .form-control {
      border: 1px solid #cecece;
      border-radius: 6px;
      height: 80px;
      line-height: 38px;
      box-shadow: none !important;
      margin-bottom: 46px;
      padding: 0 25px;
    }

    .searchBtn {
      .btn {
        max-width: 100%;
        width: 257px;
        height: 54px;
        line-height: 54px;
        padding: 0;
        text-align: center;
        background: var(--bs-color1);
        border-radius: 6px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;

        &:hover {
          background-color: #000;
        }
      }
    }
  }

  .line {
    margin: 0 -40px;
    opacity: 0.3;
    padding: 25px 0 10px;
  }

  .SearchUsingResumeWrapResult {
    .mx-n3 {
      margin: 0 -1rem;
    }

    .surr {
      background: #ffffff;
      box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.11);
      border-radius: 6px;
      text-align: center;
      padding: 30px 12px;

      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        /* identical to box height, or 178% */
        text-align: center;
        text-transform: capitalize;
        color: #202123;
        margin: 0;
      }

      hr {
        opacity: 0.1;
        margin: 1.5rem 0;
      }

      a {
        color: #000;
        display: inline-block;

        &:hover {
          color: var(--bs-color1);
          transform: scale(1.1);

          svg.replacesvg {
            path {
              fill: var(--bs-color1);
            }
          }
        }
      }
    }
  }
}

.filterWrap {
  border-top: 1px solid rgba(#000000, 0.1);

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    position: relative;
    top: 0px;

  }

  select {
    background-color: transparent;
    border-width: 0rem solid black;
    height: 30px;
    padding: 0 35px 0 15px;
    // min-width: 140px;
    background-image: url("downArrow.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 14px;
    -webkit-appearance: none;
    color: #b4b4b4;

    &:focus {
      background-color: #f1f1f1;
    }
  }

  .MuiAutocomplete-root {
    margin-bottom: 20px;
    font-size: 14px;
  }

  option {
    border: 1px solid #ccc !important;
    padding: 0;
  }

}


.resultWrap {
  .secHead {
    margin-bottom: 20px;

    a {
      color: #c4c4c4;

      &.active,
      &:hover {
        color: var(--bs-color1);
      }
    }
  }

  .cardWrap {
    &:last-child {
      .card {
        margin: 0;
      }
    }

  }

  .card {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 25px;
    cursor: pointer;

    &:hover {
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-color: var(--bs-color1);
    }

    &.active {
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-color: var(--bs-color1);
      background-color: #ffedcb;
    }

    h4 {
      font-weight: 500;
      font-size: 15px;
      color: #000;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 15px;
      text-transform: capitalize;
      color: #000;
      margin-bottom: 10px;
    }

    .h5 {
      color: #404042;
      font-size: 15px;
      text-transform: capitalize;
      padding-left: 5px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      color: #000;
      margin: 0;
    }

    p {
      padding-top: 10px;
      font-size: 14px;
      color: #000;
      margin: 0;
    }
  }

  .cardDetails {
    border: 1px solid #e5e5e5;
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;

    .partOne {
      padding: 15px 20px 7px;
      position: relative;

      @media(max-width:500px) {
        padding: 15px 6px 7px;
      }

      .closeBox {
        position: absolute;
        right: 25px;
        top: 28px;
        cursor: pointer;
        color: #000000;

        &:hover {
          color: var(--bs-color1);
        }
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: #000;
        text-transform: capitalize;
        margin-bottom: 10px;

        @media(max-width:500px) {
          font-size: 16px;
        }
      }
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      color: #000;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 18px;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 20px;
    }

    .h5 {
      color: #404042;
      font-size: 16px;
      text-transform: capitalize;
      padding-left: 5px;
      font-weight: 400;

      @media(max-width:500px) {
        font-size: 16px;
      }
    }

    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;

      @media(max-width:500px) {
        font-size: 16px;
      }
    }

    .partTwo {
      border-top: 1px solid rgba(#636363, 0.1);
      padding: 0 25px 10px;

      ul {
        margin: 0;

        li {
          position: relative;

          // padding: 0px 12px; 
          &:last-child {
            .toolTipView {
              left: inherit;
              right: 0;

              &::before {
                left: inherit;
                right: 10px;
              }

            }

          }

          &:hover {
            .toolTipView {
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }

          .toolTipView {
            position: absolute;
            width: 200px;
            visibility: hidden;
            opacity: 0;
            top: calc(100% + 7px);
            //arrow postion from top
            left: 0;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding: 10px;
            border-radius: 8px;
            border: 0;
            transition: ease 0.4s;

            &::before {
              content: "";
              position: absolute;
              bottom: 100%;
              left: 10px;
              width: 0;
              height: 0;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              border-bottom: 18px solid white;
            }

            h6 {
              font-size: 0.9rem;
              margin-bottom: 0.6rem;
              text-transform: capitalize;
            }

            p {
              font-size: 0.8rem;
              margin-bottom: 0;

              &:empty {
                display: none;
              }
            }

            // border
          }
        }
      }
    }

    .partThree {
      border-top: 1px solid rgba(#636363, 0.1);
      padding: 10px 25px 2px;

      .progressBox {
        margin-bottom: 10px;

        .number {
          position: relative;
          margin: -26px;

          @media(max-width:467px) {
            font-size: 13px;
            left: 94%;
          }
        }

        .lineBox {
          height: 8px;
          border-radius: 15px;
          background-color: #f0f2f4;
          position: relative;

          .skin {
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--bs-color1);
            height: 100%;
            border-radius: 15px;
          }
        }
      }
    }
  }

  .customTab {
    border: 1px solid #e5e5e5;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-top: 0;

    .tabHead {
      ul {
        border-bottom: 1px solid rgba(#000000, 0.1);

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }

        li {
          padding: 0 27px;

          button {
            border: 0;
            padding: 0 15px;
            line-height: 40px;
            font-size: 15px;
            color: var(--bs-color3);
            text-transform: capitalize;
            border-bottom: 2px solid transparent;
            border-radius: 0;
            margin-bottom: -1px;

            &.active {
              color: #000;
              border-bottom-color: var(--bs-color1);
            }
          }
        }
      }
    }

    .tabBody {
      padding: 14px 37px;
      animation: pageAni 0.3s;

      p {
        font-size: 14px;
        line-height: 31px;
        /* or 221% */
        text-transform: capitalize;
        color: #000000;
      }

      .feedBackWrap {
        // margin: -30px -37px;

        table {
          thead {
            background-color: #f6f6f6;

            th {
              padding: 20px 37px;

              &:last-child {
                text-align: center;
              }
            }
          }

          tbody {
            border: 0;

            td {
              border-color: rgba(#928f8f, 0.1);
              padding: 20px 37px;

              &:last-child {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .secFooter {
    padding-top: 40px;

    // margin-bottom: 130px;
    ul {
      li {
        padding: 6px;

        button {
          padding: 0;
          box-shadow: none !important;
        }

        a {
          background-color: #efeeee;
          width: 44px;
          height: 44px;
          border-radius: 5px;
          color: #000;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            // background-color: var(--bs-color1);
            color: #fff;
          }
        }
      }
    }
  }

  .searchTag {
    margin-bottom: 80px;

    ul {
      li {
        padding-top: 13px;
        padding-bottom: 13px;

        a {
          text-transform: capitalize;
          background-color: #f1f1f1;
          border-radius: 6px;
          padding: 16px 10px;
          color: #0d0c0c;
          font-size: 15px;

          svg {
            margin-right: 7px;
          }

          &:hover {
            background-color: var(--bs-color1);
          }
        }
      }
    }
  }
}

.resumeName {
  // border-bottom: 2px solid orange;
  // text-shadow: 5px 17px 15px orange, 12px 22px 42px orange;
  margin: 10px 10px;
  text-transform: none !important;
}

.OnCampusEventsTab {
  .loginPhase {
    border: 1px solid #ede7e7;
    width: 613px;
    max-width: 100%;
    text-align: center;
    padding: 50px 30px;
    margin: auto;

    h1 {
      font-weight: 500;
      font-size: 35px;
      line-height: 32px;
      /* identical to box height, or 91% */
      text-align: center;
      color: #202123;
      margin-bottom: 35px;
    }

    .btn {
      font-size: 18px;
      background-color: var(--bs-color1);
      color: #fff;
      padding: 12.5px 70.8px;
      border-radius: 6px;

      &:hover {
        background-color: #000;
      }
    }
  }

  .afterLoginShowNav {
    padding: 20px 0;

    ul {
      display: flex;
    }

    li {
      padding-right: 70px;

      &:first-child {
        padding-left: 55px;
      }

      a {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
        text-transform: capitalize;
        color: #7a7a7a;

        &.active,
        &:hover {
          color: #000;
        }
      }
    }
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  .SearchJobWidget {
    .TabBoxMain .topNav ul li label span {
      line-height: 50px;
      font-size: 14px;
    }

    .ResumeFeedBack {
      padding: 20px 40px;

      label {
        height: 160px;
        margin-bottom: 25px;

        .cont {
          svg {
            height: 30px;
            width: auto;
          }

          span {
            font-size: 14px;
            padding-top: 5px;
          }
        }
      }

      .form-control {
        height: 44px;
        line-height: 21px;
        font-size: 0.9rem;
        margin-bottom: 20px;
      }

      .searchBtn {
        .btn {
          width: 180px;
          height: 42px;
          line-height: 42px;
          font-size: 12px;
        }
      }
    }

    .uploadYourResume {
      padding: 20px 40px;

      label {
        height: 160px;
        margin-bottom: 25px;

        .cont {
          svg {
            height: 30px;
            width: auto;
          }

          span {
            font-size: 14px;
            padding-top: 5px;
          }
        }
      }

      .searchBtn {
        .btn {
          width: 180px;
          height: 42px;
          line-height: 42px;
          font-size: 12px;
        }
      }
    }

    .OnCampusEventsTab {
      padding: 20px 40px;

      .loginPhase {
        padding: 20px;

        h1 {
          font-size: 1.8rem;
          margin-bottom: 1rem;
        }

        .btn {
          padding: 10px 54px;
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 1099px) {
  .SearchJobWidget {
    .TabBoxMain {
      .suk-Row {
        .btn-Custom {
          padding: 0 15px;
          height: 48px;
          line-height: 48px;
          font-size: 14px;
          width: auto;
        }

        .inputBox {
          padding: 0 12px;

          b {
            font-size: 13px;
          }

          input {
            font-size: 13px;
            padding: 0 10px;
            height: 48px;
            line-height: 22px;
          }
        }
      }

      .topNav {
        ul {
          padding-bottom: 2px;

          li {
            label {
              span {
                font-size: 15px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .resultWrap .secFooter {
    padding-top: 60px;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .SearchJobWidget {

    .ResumeFeedBack,
    .uploadYourResume {
      label {
        height: 160px;

        .cont {
          svg {
            height: 30px;
          }

          span {
            font-size: 14px;
            line-height: 1;
          }
        }
      }

      .form-control {
        font-size: 14px;
        height: 56px;
        line-height: 28px;
      }

      .searchBtn .btn {
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 575px) {
  .SearchJobWidget {
    .suk-Row {
      >div {
        margin-bottom: 15px;
      }
    }

    .row {
      padding: 20px 10px;
    }

    .TabBoxMain {
      .topNav {
        ul {
          li {
            padding: 0 15px;

            label {
              span {
                font-size: 11px;
                line-height: 48px;
              }
            }
          }
        }
      }

      .suk-Row {
        .btn-Custom {
          width: 100%;
        }
      }
    }

    .ResumeFeedBack,
    .uploadYourResume {
      padding: 25px;
    }
  }

  .resultWrap {
    .cardDetails {
      .partTwo ul {
        justify-content: space-between;

        li {
          padding-right: 00px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .customTab {
      .tabBody {
        padding: 5px 20px;

        .feedBackWrap {
          margin: 0;

        

          table {
            thead {
              th {
                padding: 15px 7px;
                font-size: 10px;
                vertical-align: middle;
              }
            }

            tbody {
              td {
                padding: 15px 7px;
                font-size: 10px;
              }
            }
          }
        }
      }

      .tabHead {
        ul {
          li {
            padding: 0 15px;

            .btn {
              line-height: 54px;
              padding: 0 9px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .secFooter ul {
      li {
        padding: 3px;

        a {
          width: 30px;
          height: 30px;
          font-size: 12px;
        }
      }

      .btn {
        svg {
          width: 30px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .SearchJobWidget {
    .SearchUsingResumeWrapResult {
      .surr {
        padding: 20px 5px;

        h5 {
          font-size: 16px;
        }

        hr {
          margin: 1.2rem 0;
        }
      }
    }
  }
}

.DashboardModule .dashboardMenu nav .new-design-all-dakhboard-menu li a.active,
.DashboardModule .dashboardMenu nav ul li a:hover {
  svg {
    filter: invert(40%) sepia(51%) saturate(2878%) hue-rotate(20deg) brightness(125%) contrast(97%);
  }
}

.companyNameField {
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  legend {
    display: none;
  }
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px 9px !important;
}

.filterWrap {

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0 !important;
    // background-image: url(./downArrow.svg); // arrows were clashing.
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
    font-size: 14px; // to match other dropdowns.

    &:focus {
      // background-image: url(./downArrow.svg)
    }

    @media (max-width:1024px) {
      background-position: calc(100% - 6px) center;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0 !important;
  }

}

.job-title-top {
  display: flex;

  @media(max-width:467px) {
    flex-wrap: wrap;
  }

}

.d-flex {
  margin-left: 25px;

  @media(max-width:467px) {
    margin-left: 5px
  }
}

.dev-title {
  h4 {
    font-size: 15px;
  }
}

.job-title-down {
  padding-top: 0px !important;
}


// .Mui-focusd fieldset
// { border-color: red !important;}


.resultWrap .secFooter {
  @media(max-width:500px) {
    padding-top: 21px;
    margin-bottom: 49px;
  }
}


.resultWrap .secFooter ul li button {
  @media(max-width:500px) {
    font-size: 12px !important;
  }
}

.Mui-selected {
  @media(max-width:500px) {
    width: 26px !important;
    height: 31px !important;
  }
}

.secFooter {
  nav {
    .MuiPagination-ul {
      @media(max-width:500px){
        width: 317px;
        margin: 0 auto;
      }
      li{
        @media(max-width:500px) {
          // flex-wrap: nowrap;
          // overflow: auto;
          width: 32px !important;
        }
      }
     

    }
  }
}

.reumeAlert{
  font-size: 14px ;
  font-weight: 300;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin: 20px 0 20px 0; 

  .btn-Custom {
    padding: 0 15px;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    width: auto;
    text-align: center;
    background: var(--bs-color1);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: .1s ease-in-out;
    display: block;
    border:1px solid white;
   
    &:hover{
      background-color: black;
    }
  }

// .uploadResume{
//   width: 140px;
//   height: 36px;
//   line-height: 30px;
//   padding: 0;
//   text-align: center;
//   background: var(--bs-color1);
//   border-radius: 6px;
//   font-size: 14px;
//   font-weight: 500;
//   color: #fff;
//   transition: .1s ease-in-out;
//   display: block;
 


// }
  
  .resumeAlertLoader{
    font-size: 10px;
    width: 14px !important;
    height: auto !important;
    position: relative;
    top: 2px;
    margin: 0 5px 0 0; 
  }
}

.sign_in_bottom{
  margin: auto;
  padding: 0 15px;
    height: 34px !important;
    line-height: 32px !important;
    font-size: 12px !important;
    width: auto;
    text-align: center;
    background: var(--bs-color1);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: .1s ease-in-out;
    display: block;
    border: 1px solid white;
    margin-top: 10px;

}