.innerBanner {
    position: relative;
    margin-top: 69px;
    img {
        width: 100%;
        display: block;
    }
    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    h1 {
        font-weight: 500;
        font-size: 55px;
        line-height: 64px;
        text-transform: uppercase;
        color: #000000;
    }
    p {
        font-size: 18px;
        line-height: 30px;
        text-transform: capitalize;
        color: #000000;
    }
}
@media (min-width: 1100px) and (max-width: 1600px) {
    .innerBanner {
        // img {
        //     height: 120px;
        //     object-fit: cover;
        // }
        h1 {
            font-size: 1.8rem;
            line-height: normal;
        }
        p {
            line-height: normal;
            margin: 0;
            font-size: 14px;
        }
    }
}
@media (max-width: 991px) {
    .innerBanner h1 {
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    .innerBanner {
        margin: 0;
        h1 {
            font-size: 22px;
            line-height: 1;
        }
        p {
            font-size: 13px;
            line-height: 20px;
        }
    }
}

@media (max-width: 479px) {
    .innerBanner {
        margin: 0;
        h1 {
            font-size: 14px;
            line-height: 1;
            margin: 0;
        }
        p {
            font-size: 11px;
            line-height: 20px;
            margin: 0;
        }
    }
}
