header {
  padding: 10px 0;
  border-bottom: 1px solid rgba(#9e9e9e, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  .signIn {
    a {
      font-size: 15px;
      line-height: 18px;
      text-transform: capitalize;
      color: #212529;
      // margin-top: 3px;
      &:hover {
        color: var(--bs-color1);
      }
    }
  }
  .postAJob {
    a {
      color: var(--bs-color1);
      &:hover {
        color: #000;
      }
    }
  }

  .dropdown-item {
    color: black;
    padding: 10px 15px;
    text-decoration: none;
    display: block;
    border-collapse: separate;
    border-bottom: 1px solid #ccc;
  }
  .dropdown-item:last-child {
    border-bottom: 0px;
  }

  .profileDropmenu {
    border: 1px solid #ccc !important;
    padding: 0;
  }
  .Dash {
    // border-bottom: 1px solid orange;
  }
  .UpdatePro {
    border-bottom: 1px dotted orange;
  }
  .resume {
    border-bottom: 1px dotted orange;
  }
}

@media (min-width: 1100px) and (max-width: 1600px) {
  header .signIn {
    a {
      font-size: 1rem;
    }
    .profileDropmenu {
      a {
        font-size: 0.85rem;
        line-height: normal;
        padding: 8px 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  header {
    position: static;
  }
}
@media (max-width: 480px) {
  header {
    .justForGap {
      display: block !important;
    }
    .ms-custom {
      margin-left: auto;
    }
  }
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.dropdown-menu {
  inset: 10px auto auto -40px !important;
  border: none !important;
}

.logout {
  color: #000 !important;
  font-weight: normal !important;
}
.logout:hover {
  color: #fbaf41 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  margin-top: 8px;
}
.search_job {
  color: #000;
}
.search_job:hover {
  color: #000 !important;
}
