.dashboardPageWrap2 {
  .countingCardsWrap2 {
    width: 700px;
    max-width: 100%;
    background: #ffffff;
    // box-shadow: 0px 5px 18px rgb(0 0 0 / 7%);
    border: 0;
    margin: 0 auto 80px;
    padding: 1.25rem 2.5rem;
  }
}

.text-deco {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis !important;
}

.singleDiv {
  box-shadow: -1px 7px 14px #8e8d7ead;
  height: 200px;
  margin: 20px 20px;
}
.paginationFooter {
  margin: 20px 0px;
}
.text-orange {
  color: orange;
  transition: ease-in 0.2s;
  &:hover {
    color: black;
    transition: ease-in-out 0.2s;
  }
}
.read-or-hide {
  cursor: pointer;
}
.btn1 {
  width: auto;
  height: auto;
  line-height: 36px;
  padding: 0px 20px;
  text-align: center;
  background: var(--bs-color1);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  transition: 0.2s ease-in;
}
.removefromFav {
  text-align: end;
  .btn-Custom {
    width: auto;
    height: 33px !important;
    line-height: 36px;
    padding: 0px 20px 20px 20px;
    text-align: center;
    background: var(--bs-color1);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    margin-left: auto;

    &:hover {
      transition: 0.2s ease-out;
      background-color: #000;
    }
  }
}
.favourite_title1 {
  font-size: 15px;
  padding-left: 8px;
  white-space: nowrap;
  overflow: hidden  ;
  text-overflow: ellipsis  ;
}

.res-title{
  font-size: 18px;
}

.res-next{
  font-size: 15px;
}

// .btn-remove{
//   height: 33px !important;
//     display: flex;
//     align-items: center;
//     margin-left: auto;
// }