.footerCust {
  // position: relative;
  // top: 36vh;
}

.noEvents {
  min-height: 50vh;
  text-align: center;
}
.nocontentGap{
  margin: 70px 0 0 0;
}